const actions = {
    OVERVIEW_DATA_BEGIN: 'FORCAST_OVERVIEW_BEGIN',
    OVERVIEW_DATA_SUCCESS: 'FORCAST_OVERVIEW_SUCCESS',
    OVERVIEW_DATA_ERR: 'FORCAST_OVERVIEW_ERR',

    overviewDataBegin: () => {
        return {
            type: actions.OVERVIEW_DATA_BEGIN,
        };
    },

    overviewDataSuccess: (data) => {
        return {
            type: actions.OVERVIEW_DATA_SUCCESS,
            data,
        };
    },

    overviewDataErr: (err) => {
        return {
            type: actions.OVERVIEW_DATA_ERR,
            err,
        };
    },
};

export default actions;
