import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const DataTable = lazy(() => import('../../container/pages/UserListDataTable'));
const CryptoClientsPage = lazy(() => import('../../container/pages/cryptoClients/CryptoClientsPage'));

function PagesRoute() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/dataTable`} component={DataTable} />
      <Route path={`${path}/cryptoClients`} component={CryptoClientsPage} />
    </Switch>
  );
}

export default PagesRoute;
