const actions = {
    TRANSACTIONS_READ_BEGIN: 'TRANSACTIONS_READ_BEGIN',
    TRANSACTIONS_READ_SUCCESS: 'TRANSACTIONS_READ_SUCCESS',
    TRANSACTIONS_READ_ERR: 'TRANSACTIONS_READ_ERR',

    transactionsReadBegin: () => {
        return {
            type: actions.TRANSACTIONS_READ_BEGIN,
        };
    },

    transactionsReadSuccess: (data) => {
        return {
            type: actions.TRANSACTIONS_READ_SUCCESS,
            data,
        };
    },

    transactionsReadErr: (err) => {
        return {
            type: actions.TRANSACTIONS_READ_ERR,
            err,
        };
    },
};

export default actions;