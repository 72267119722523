import React from 'react';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';
import { SettingDropdwon } from './auth-info-style';
import { Popover } from '../../popup/popup';
import Heading from '../../heading/heading';

function Settings() {
  const content = (
    <SettingDropdwon>
      <div className="setting-dropdwon">
        <Row gutter="10">
          <Col sm={12}>
            <figure className="setting-dropdwon__single d-flex">
              <img src={require('../../../static/img/icon/014-document.png')} alt="" />
              <figcaption>
                <Heading as="h5">Invite new users</Heading>
                <p>Get the whole team on the platform </p>
              </figcaption>
            </figure>
          </Col>
          <Col sm={12}>
            <figure className="setting-dropdwon__single d-flex">
              <img src={require('../../../static/img/icon/010-home.png')} alt="" />
              <figcaption>
                <Heading as="h5">Customize Dashboard</Heading>
                <p>Edit your view and visualization contents</p>
              </figcaption>
            </figure>
          </Col>
          <Col sm={12}>
            <figure className="setting-dropdwon__single d-flex">
              <img src={require('../../../static/img/icon/013-document-1.png')} alt="" />
              <figcaption>
                <Heading as="h5">Edit Case Management Settings</Heading>
                <p>Customize workflow and integrations</p>
              </figcaption>
            </figure>
          </Col>
          <Col sm={12}>
            <figure className="setting-dropdwon__single d-flex">
              <img src={require('../../../static/img/icon/015-color-palette.png')} alt="" />
              <figcaption>
                <Heading as="h5">Influence Roadmap</Heading>
                <p>Provide feedback and features and functionality </p>
              </figcaption>
            </figure>
          </Col>
        </Row>
      </div>
    </SettingDropdwon>
  );

  return (
    <div className="settings">
      <Popover placement="bottomRight" content={content} action="click">
        <Link to="#" className="head-example">
          <FeatherIcon icon="settings" size={20} />
        </Link>
      </Popover>
    </div>
  );
}

export default Settings;
