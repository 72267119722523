import actions from './actions';

const initialState = {
    data: [],
    loading: false,
    error: null,
};

const {
    TRANSACTIONS_READ_BEGIN,
    TRANSACTIONS_READ_SUCCESS,
    TRANSACTIONS_READ_ERR,
} = actions;

const TransactionsReducer = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case TRANSACTIONS_READ_BEGIN:
            return {
                ...state,
                loading: true,
            };
        case TRANSACTIONS_READ_SUCCESS:
            return {
                ...state,
                data,
                loading: false,
            };
        case TRANSACTIONS_READ_ERR:
            return {
                ...state,
                error: err,
                loading: false,
            };
        default:
            return state;
    }
};

export default TransactionsReducer;
