import React from 'react';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import { NestedDropdwon } from './auth-info-style';
import { Popover } from '../../popup/popup';
import Heading from '../../heading/heading';

function Support() {
  const content = (
    <NestedDropdwon>
      <div className="support-dropdwon">
        <ul>
          <Heading as="h5">FAQs</Heading>
          <li>
            <Link to="/admin/faq">How to use the app</Link>
          </li>
          <li>
            <Link to="/admin/faq">How detection works</Link>
          </li>
          <li>
            <Link to="/admin/faq">How case management works</Link>
          </li>
        </ul>
        <ul>
          <Heading as="h5">Documentation</Heading>
          <li>
            <Link to="/admin/faq">API reference</Link>
          </li>
          <li>
            <Link to="/admin/faq">Security</Link>
          </li>
        </ul>
      </div>
    </NestedDropdwon>
  );

  return (
    <div className="support">
      <Popover placement="bottomLeft" content={content} action="click">
        <Link to="#" className="head-example">
          <FeatherIcon icon="help-circle" size={20} />
        </Link>
      </Popover>
    </div>
  );
}

export default Support;
