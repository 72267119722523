const actions = {
    TRANSACTION_DATA_READ_BEGIN: 'TRANSACTION_DATA_READ_BEGIN',
    TRANSACTION_DATA_READ_SUCCESS: 'TRANSACTION_DATA_READ_SUCCESS',
    TRANSACTION_DATA_READ_ERR: 'TRANSACTION_DATA_READ_ERR',

    TRANSACTION_DATA_UPDATE_BEGIN: 'TRANSACTION_DATA_UPDATE_BEGIN',
    TRANSACTION_DATA_UPDATE_SUCCESS: 'TRANSACTION_DATA_UPDATE_SUCCESS',
    TRANSACTION_DATA_UPDATE_ERR: 'TRANSACTION_DATA_UPDATE_ERR',


    transactionDataUpdateBegin: () => {
        return {
            type: actions.TRANSACTION_DATA_READ_BEGIN,
        };
    },

    transactionDataUpdateSuccess: (data) => {
        return {
            type: actions.TRANSACTION_DATA_READ_SUCCESS,
            data,
        };
    },

    transactionDataUpdateErr: (err) => {
        return {
            type: actions.TRANSACTION_DATA_READ_ERR,
            err,
        };
    },

    transactionDataReadBegin: () => {
        return {
            type: actions.TRANSACTION_DATA_UPDATE_BEGIN,
        };
    },

    transactionDataReadSuccess: (data) => {
        return {
            type: actions.TRANSACTION_DATA_UPDATE_SUCCESS,
            data,
        };
    },

    transactionDataReadErr: (err) => {
        return {
            type: actions.TRANSACTION_DATA_UPDATE_ERR,
            err,
        };
    },
};

export default actions;
