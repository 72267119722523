import actions from './actions';
import wireTransaction from '../../demoData/wireTransaction.json';

const initialState = {
    data: wireTransaction,
    loading: false,
    error: null,
};

const {
    TRANSACTION_DATA_READ_BEGIN,
    TRANSACTION_DATA_READ_SUCCESS,
    TRANSACTION_DATA_READ_ERR,

    TRANSACTION_DATA_UPDATE_BEGIN,
    TRANSACTION_DATA_UPDATE_SUCCESS,
    TRANSACTION_DATA_UPDATE_ERR,
} = actions;

const TransactionDataReducer = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case TRANSACTION_DATA_READ_BEGIN:
            return {
                ...state,
                sLoading: true,
            };
        case TRANSACTION_DATA_READ_SUCCESS:
            return {
                ...state,
                data,
                sLoading: false,
            };
        case TRANSACTION_DATA_READ_ERR:
            return {
                ...state,
                error: err,
                sLoading: false,
            };
        case TRANSACTION_DATA_UPDATE_BEGIN:
            return {
                ...state,
                loading: true,
            };
        case TRANSACTION_DATA_UPDATE_SUCCESS:
            return {
                ...state,
                data,
                loading: false,
            };
        case TRANSACTION_DATA_UPDATE_ERR:
            return {
                ...state,
                error: err,
                loading: false,
            };
        default:
            return state;
    }
};

export default TransactionDataReducer;
