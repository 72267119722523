const actions = {
    CRYPTO_CLIENTS_OVERVIEW_DATA_BEGIN: 'CRYPTO_CLIENTS_OVERVIEW_DATA_BEGIN',
    CRYPTO_CLIENTS_OVERVIEW_DATA_SUCCESS: 'CRYPTO_CLIENTS_OVERVIEW_DATA_SUCCESS',
    CRYPTO_CLIENTS_OVERVIEW_DATA_ERR: 'CRYPTO_CLIENTS_OVERVIEW_DATA_ERR',

    overviewDataBegin: () => {
        return {
            type: actions.CRYPTO_CLIENTS_OVERVIEW_DATA_BEGIN,
        };
    },

    overviewDataSuccess: (data) => {
        return {
            type: actions.CRYPTO_CLIENTS_OVERVIEW_DATA_SUCCESS,
            data,
        };
    },

    overviewDataErr: (err) => {
        return {
            type: actions.CRYPTO_CLIENTS_OVERVIEW_DATA_ERR,
            err,
        };
    },
};

export default actions;
