import actions from "./actions";

const {
    OVERVIEW_DATA_BEGIN,
    OVERVIEW_DATA_SUCCESS,
    OVERVIEW_DATA_ERR
} = actions

const initialState = {
    data: [],
    url: null,
    fileLoading: false,
    loading: false,
    error: null,
};

const dashboardReducer = (state = initialState, action) => {
    const {type, data, err} = action;
    switch (type) {
        case OVERVIEW_DATA_BEGIN:
            return {
                ...state,
                overviewLoading: true,
            };
        case OVERVIEW_DATA_SUCCESS:
            return {
                ...state,
                overviewData: data,
                overviewError: false,
                overviewLoading: false,
            };
        case OVERVIEW_DATA_ERR:
            return {
                ...state,
                overviewError: err,
                overviewLoading: false,
            };
        default:
            return state;
    }
}

export default dashboardReducer;