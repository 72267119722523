import React from 'react';
import { Menu } from 'antd';
import { NavLink, useRouteMatch } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';
import { NavTitle } from './style';
import versions from '../demoData/changelog.json';

function MenuItems({ darkMode, toggleCollapsed, topMenu }) {
  const { path } = useRouteMatch();
  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');
  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );

  const onOpenChange = (keys) => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      theme={darkMode && 'dark'}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
              `${
                mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
              }`,
            ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      openKeys={openKeys}
    >
      <Menu.Item
          icon={
              !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/dashboard`}>
                    <FeatherIcon icon="home" />
                  </NavLink>
              )
          }
          key="dashboard"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/dashboard`}>
          Dashboard
        </NavLink>
      </Menu.Item>

      <Menu.Item
          icon={
              !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/project/view/list`}>
                    <FeatherIcon icon="target" />
                  </NavLink>
              )
          }
          key="views"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/project/view/list`}>
          Cases
        </NavLink>
      </Menu.Item>

      <Menu.Item
          icon={
              !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/users/dataTable`}>
                    <FeatherIcon icon="users" />
                  </NavLink>
              )
          }
          key="user-table"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/users/cryptoClients`}>
          Clients
        </NavLink>
      </Menu.Item>


        <Menu.Item
            icon={
                !topMenu && (
                    <NavLink className="menuItem-iocn" to={`${path}/users/dataTable`}>
                        <FeatherIcon icon="activity" />
                    </NavLink>
                )
            }
            key="transactions-table"
        >
            <NavLink onClick={toggleCollapsed} to={`${path}/users/dataTable`}>
                Transactions
            </NavLink>
        </Menu.Item>

        {!topMenu && <NavTitle className="sidebar-nav-title">Information</NavTitle>}
        <Menu.Item
            icon={
                !topMenu && (
                    <NavLink className="menuItem-iocn" to={`${path}/faq`}>
                      <FeatherIcon icon="help-circle" />
                    </NavLink>
                )
            }
            key="faq"
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/faq`}>
            FAQs
          </NavLink>
        </Menu.Item>

        <Menu.Item
            icon={
                !topMenu && (
                    <NavLink className="menuItem-iocn" to={`${path}/changelog`}>
                      <FeatherIcon icon="compass" />
                    </NavLink>
                )
            }
            key="changelog"
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/changelog`}>
            Changelog
            <span className="badge badge-primary menuItem">{versions[0].version}</span>
          </NavLink>
        </Menu.Item>

        {!topMenu && <NavTitle className="sidebar-nav-title">Testing Area</NavTitle>}
        <Menu.Item
            icon={
                !topMenu && (
                    <NavLink className="menuItem-iocn" to={`${path}/playground`}>
                        <FeatherIcon icon="circle" />
                    </NavLink>
                )
            }
            key="playground"
        >
            <NavLink onClick={toggleCollapsed} to={`${path}/playground`}>
                Transaction Playground
            </NavLink>
        </Menu.Item>
    </Menu>
  );
}

MenuItems.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
